// card
.card {
  .card-header,
  .card-body,
  .card-footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

// accordion
.accordion-item.card {
  &:first-of-type:not(:only-of-type) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:last-of-type:not(:only-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }

  .card-header {
    padding: 0;
    margin-bottom: calc(-1 * $border-width);
    border-color: $accordion-border-color;
    border-radius: 0;

    .card-header-tabs {
      flex-wrap: nowrap;
      margin-right: calc(0.5 * $card-cap-padding-x);
      margin-bottom: calc(-1 * $border-width);
      margin-left: calc(0.5 * $card-cap-padding-x);
      overflow-x: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .accordion-button {
    background-color: transparent;

    &:focus {
      box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
        $input-btn-focus-color inset;
    }

    &:not(.collapsed) {
      color: inherit;
      background-color: transparent;

      &:not(:focus) {
        box-shadow: none;
      }
    }

    &::after {
      content: none;
    }
  }
}

// dropdown
.dropdown[data-popper-reference-hidden] {
  pointer-events: none;
  visibility: hidden;
}

.dropdown-toggle:not(.dropdown-toggle-split)::after {
  display: none !important;
}

// form
.form-control[readonly] {
  color: $input-disabled-color;
  background-color: $input-disabled-bg;
  border-color: $input-disabled-border-color;
  opacity: 1;
}

// nav & tab
.tab-pane {
  overflow: hidden;
}

// table
.table-responsive {
  overflow-y: auto;
}

.table {
  > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 10;
    border: none;

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      left: 0;
    }

    &::before,
    &::after {
      position: absolute;
      width: 100%;
      height: 1px;
      content: '';
      background: $table-border-color;
    }
  }

  > tbody > tr > td {
    background-color: $body-bg;
  }

  > tfoot > tr > th {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: $body-bg;
    border: none;

    &::before {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background: $table-border-color;
    }
  }

  > thead > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th {
    padding: 0.75rem 0.25rem;
    white-space: nowrap;
    vertical-align: middle;

    &:first-child {
      padding-left: 0.75rem;
    }

    &:last-child {
      padding-right: 0.75rem;
    }
  }
}

// ng-select
.ng-select.custom {
  @include border-radius($form-select-border-radius, 0);

  > .ng-select-container {
    min-height: $input-height;
    color: $form-select-color;
    background-color: $form-select-bg;
    border: $form-select-border-width solid $form-select-border-color;
    border-radius: inherit;

    &:hover {
      box-shadow: none;
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: $form-select-disabled-bg;
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $form-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow(
        $form-select-box-shadow,
        $form-select-focus-box-shadow
      );
    } @else {
      box-shadow: $form-select-focus-box-shadow;
    }
  }
}

.input-group > .ng-select.custom {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;

  &.ng-select-focused:not(.ng-select-opened) {
    z-index: 5;
  }
}

.ng-dropdown-panel {
  z-index: 1055 !important;
}

@mixin custom-select-validation-state(
  $state,
  $color,
  $icon,
  $tooltip-color,
  $tooltip-bg-color,
  $focus-box-shadow,
  $border-color
) {
  .ng-select.custom {
    @include form-validation-state-selector($state) {
      > .ng-select-container {
        border-color: $color;
      }

      &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: $border-color;
        box-shadow: $focus-box-shadow;
      }
    }
  }
}

@each $state, $data in $form-validation-states {
  @include custom-select-validation-state($state, $data...);
}
